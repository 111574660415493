import { VuexMutationKey, VuexStateKey, VuexActionKey } from '../vuexEnum'
import { AllTypeEnumList, apiQueryAllEnum } from '@/api/enum'
import { Module } from 'vuex'
const enumModule: Module<
	{
		[x: string]: unknown
	},
	unknown
> = {
	namespaced: true,
	state: {
		[VuexStateKey.serverAllEnum]: {},
		[VuexStateKey.pageEnumList]: {
			ifContainCutEnumList: [
				{
					index: 1,
					desc: '切割成功',
				},
				{
					index: 2,
					desc: '切割失败',
				},
			],
			ifErrorRowEnumList: [
				{
					index: 1,
					desc: '无错误行',
				},
				{
					index: 2,
					desc: '有错误行',
				},
			],
			ifErrorCauseEnumList: [
				{
					index: 1,
					desc: '无错因',
				},
				{
					index: 2,
					desc: '有错因',
				},
			],
		},
	},
	mutations: {
		[VuexMutationKey.updateServerAllEnum](state, allEnum: AllTypeEnumList) {
			state[VuexStateKey.serverAllEnum] = allEnum
		},
	},
	actions: {
		async [VuexActionKey.queryAllEnum]({ commit }) {
			const allEnum = await apiQueryAllEnum()
			commit(VuexMutationKey.updateServerAllEnum, allEnum)
		},
	},
}

export default enumModule
