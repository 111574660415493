import G from '@/config/config'
import { VuexStateKey } from '@/store/vuexEnum'
import store from '@/store'

export const setPageTitle = function (title: string | undefined): void {
	document.title = title || G.defaultTitle
}

export function onBackReloadPage() {
	let isPageHide = false
	window.addEventListener('pageshow', function () {
		if (isPageHide) {
			window.location.reload()
		}
	})
	window.addEventListener('pagehide', function () {
		isPageHide = true
	})
}

interface dataItem {
	[key: string]: unknown
	subAuthList?: dataItem[]
}

// 获取每个路由的配置信息
export function pagePermissionSeting(prop: string, value: string, data: dataItem[]): dataItem | null {
	for (let i = 0; i < data.length; i++) {
		const item = data[i]
		if (item[prop] === value) {
			return item
		}
		if (item.subAuthList && item.subAuthList.length) {
			const isExit = pagePermissionSeting(prop, value, item.subAuthList)
			if (isExit) {
				return isExit
			}
		}
	}
	return null
}

export function getOpenType(authValue: string) {
	const auths = store.state[VuexStateKey.auths] as unknown as dataItem[]
	return pagePermissionSeting('authValue', authValue, auths)?.openType
}
