import { Module } from 'vuex'
import { VuexActionKey, VuexMutationKey, VuexStateKey } from '@/store/vuexEnum'
import { EnumItem } from '@/api/enum'
import { apiQueryQualityTestErrorEnumByType } from '@/api/markingManage/subTaskCommon'

const labellingModule: Module<
	{
		[x: string]: unknown
	},
	unknown
> = {
	namespaced: true,
	state: {
		[VuexStateKey.qualityTestErrorEnum]: {},
		[VuexStateKey.operationType]: null,
	},
	mutations: {
		[VuexMutationKey.updateQualityTestErrorEnum](state, qualityTestErrorEnum: EnumItem[]) {
			state[VuexStateKey.qualityTestErrorEnum] = qualityTestErrorEnum
		},
		[VuexMutationKey.updateOperationType](state, operationType: number) {
			state[VuexStateKey.operationType] = operationType
		},
	},
	actions: {
		async [VuexActionKey.queryQualityTestErrorEnum]({ commit }, payload) {
			const qualityTestErrorEnum = await apiQueryQualityTestErrorEnumByType({ subTaskType: payload })
			commit(VuexMutationKey.updateQualityTestErrorEnum, qualityTestErrorEnum)
		},
	},
}

export default labellingModule
