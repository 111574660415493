import { createStore } from 'vuex'
import { VuexActionKey, VuexModuleKey, VuexMutationKey, VuexStateKey } from './vuexEnum'
import { frameworkState, frameworkMutations } from '@kzfe/learnable-lib/package/vue3/framework/store'
import enumModule from './modules/enum'
import labellingModule from '@/store/modules/labelling'

const store = createStore({
	state: {
		...frameworkState,
		[VuexStateKey.userList]: [],
	},
	mutations: {
		...frameworkMutations,
		[VuexMutationKey.updateUserList](state, payload) {
			state.userList = payload
		},
	},
	actions: {
		[VuexActionKey.queryUserList]({ commit }, payload) {
			commit(VuexMutationKey.updateUserList, payload)
		},
	},
	modules: { [VuexModuleKey.enumModule]: enumModule, [VuexModuleKey.labellingModule]: labellingModule },
})

export default store
