import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import { MotionPlugin } from '@vueuse/motion'

import App from '@kzfe/learnable-lib/package/vue3/framework/App.vue'
import router from './router'
import store from './store'

import '@/styles/index.scss'
import 'img-mark/dist/style.css'
import '@kzfe/learnable-lib/dist/style.css'
import 'element-plus/theme-chalk/index.css'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/es/locale/lang/zh-cn'
import highlight from '@/utils/common/highlight' // 高亮指令v-highlight
import disableOption from '@/utils/common/disableOption' // select下拉框禁用选项
import { registDirective } from '@kzfe/learnable-lib/package/vue3/framework/directive'
import VueGtag from 'vue-gtag-next'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import packageJson from '../package.json'

import { EnvName } from '@/config/NodeEnv'
const app = createApp(App)
registDirective(app)
app.config.globalProperties.$t = undefined

import { datafluxRum } from '@cloudcare/browser-rum'
import { DOMAIN_MAP } from '@/config/config'
import { setVueCompGlobalConfig } from '@kzfe/learnable-lib'
/** 开发环境不注册 */
if (process.env.NODE_ENV !== 'development') {
	// 观测云
	// datafluxRum.init({
	// 	applicationId: 'QUESTION_BANK_V3',
	// 	datakitOrigin: process.env.NODE_ENV === EnvName.PRODUCTION ? '//datakit-online.kezhitech.com' : '//datakit-test.kezhitech.com', // 协议（包括：//），域名（或IP地址）[和端口号]
	// 	env: import.meta.env.MODE,
	// 	version: packageJson.version,
	// 	sessionSampleRate: 100,
	// 	sessionReplaySampleRate: 70,
	// 	trackInteractions: true,
	// 	traceType: 'ddtrace',
	// 	allowedTracingOrigins: Object.values(DOMAIN_MAP),
	// })
	// datafluxRum.startSessionReplayRecording()
}

// if (process.env.NODE_ENV === EnvName.PRODUCTION) {
// 	Sentry.init({
// 		app,
// 		dsn: 'https://465ebbf4d74f41f881950a4e74b6275f@sentry.kezhitech.com/32',
// 		release: packageJson.version,
// 		integrations: [new BrowserTracing()],
// 		ignoreErrors: [
// 			// Random plugins/extensions
// 			'top.GLOBALS',
// 			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
// 			'originalCreateNotification',
// 			'canvas.contentDocument',
// 			'MyApp_RemoveAllHighlights',
// 			'http://tt.epicplay.com',
// 			"Can't find variable: ZiteReader",
// 			'jigsaw is not defined',
// 			'ComboSearch is not defined',
// 			'http://loading.retry.widdit.com/',
// 			'atomicFindClose',
// 			// Facebook borked
// 			'fb_xd_fragment',
// 			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
// 			// reduce this. (thanks @acdha)
// 			// See http://stackoverflow.com/questions/4113268
// 			'bmi_SafeAddOnload',
// 			'EBCallBackMessageReceived',
// 			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
// 			'conduitPage',
// 		],
// 		denyUrls: [
// 			// Facebook flakiness
// 			/graph\.facebook\.com/i,
// 			// Facebook blocked
// 			/connect\.facebook\.net\/en_US\/all\.js/i,
// 			// Woopra flakiness
// 			/eatdifferent\.com\.woopra-ns\.com/i,
// 			/static\.woopra\.com\/js\/woopra\.js/i,
// 			// Chrome extensions
// 			/extensions\//i,
// 			/^chrome:\/\//i,
// 			// Other plugins
// 			/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
// 			/webappstoolbarba\.texthelp\.com\//i,
// 			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
// 		],
// 		beforeSend: (event, hint) => {
// 			if (!hint) return null
// 			const { response } = hint.originalException as Record<string, any>
// 			if (response && response.status === 404) {
// 				return null
// 			}
// 			return event
// 		},
// 		tracesSampleRate: 1.0,
// 	})
// }
app.use(ElementPlus, {
	locale,
})
app.use(VueGtag)
app.use(highlight).use(disableOption)

app.use(MotionPlugin, {
	directives: {
		'main-show': {
			initial: {
				opacity: 0,
				y: 100,
			},
			enter: {
				opacity: 1,
				y: 0,
				transition: {
					type: 'spring',
					stiffness: 250,
					damping: 25,
					mass: 0.5,
				},
			},
			leave: {
				opacity: 0,
				y: -100,
			},
		},
	},
})

app.use(store).use(router).mount('#app')
registDirective(app)

setVueCompGlobalConfig({
	blankLight: true,
})

export default app
