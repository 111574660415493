import { setPageTitle } from '@/utils/common/system'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { VuexStateKey, VuexModuleKey, VuexActionKey } from '@/store/vuexEnum'
import store from '@/store'
import NProgress from 'nprogress' // progress bar
import { frameworkRoutes, routerBeforeEach, settingRoutesG, recursionGetConstRoute } from '@kzfe/learnable-lib/package/vue3/framework/router/index'
import { getRouteComponent } from '@kzfe/learnable-lib/package/vue3/framework/router/getRouteComponent'
import { initFramework } from '@kzfe/learnable-lib/package/vue3/framework/config/config'
import G, { DOMAIN_MAP } from '@/config/config'
import { isLogin } from '@kzfe/learnable-lib/package/vue3/framework/utils/common/user'
import { EnumItem } from '@/api/enum'
import Main from '@kzfe/learnable-lib/package/vue3/framework/components/Main/Main.vue'
import { trackRouter } from 'vue-gtag-next'
import { setKeepAliveAndTransition } from 'vue-page-like-app'
// 此处添加系统管理内自定义路由
frameworkRoutes
	.find(i => i.name === 'system')
	?.children?.push(
		{
			path: '/system/enumerate',
			name: '/system/enumerate',
			component: () => getRouteComponent('/system/enumerate', import('../views/enumerate/Index.vue')),
			meta: {
				title: '枚举管理',
				icon: '&#xe789;',
				permission: 'system:enumerate',
				menuShow: true,
				deepth: 2,
			},
		},
		{
			path: '/system/questionType',
			name: '/system/questionType',
			component: () => getRouteComponent('/system/questionType', import('../views/questionType/Index.vue')),
			meta: {
				title: '题型管理',
				icon: '&#xe7a4;',
				permission: 'system:questionType',
				menuShow: true,
				deepth: 2,
			},
		},
		{
			path: '/system/reviseQuestionType',
			name: '/system/reviseQuestionType',
			component: () => getRouteComponent('/system/reviseQuestionType', import('../views/reviseQuestionType/Index.vue')),
			meta: {
				title: '批改题型管理',
				icon: '&#xe7a4;',
				permission: 'system:correctionQuestionType',
				menuShow: true,
				deepth: 2,
			},
		},
		{
			path: '/system/combineQuestionType',
			name: '/system/combineQuestionType',
			component: () => getRouteComponent('/system/combineQuestionType', import('../views/combineQuestionType/Index.vue')),
			meta: {
				title: '题型归类管理',
				icon: '&#xe7a4;',
				permission: 'system:combineQuestionType',
				menuShow: true,
				deepth: 2,
			},
		},
		{
			path: '/system/accuracyStatistics',
			name: '/system/accuracyStatistics',
			component: () => getRouteComponent('/system/accuracyStatistics', import('../views/accuracyStatistics/Index.vue')),
			meta: {
				title: '作答正确率规则设置',
				icon: '&#xe6de;',
				permission: 'system:accuracyStatistics',
				menuShow: true,
				deepth: 2,
			},
		}
	)

const routes: Array<RouteRecordRaw> = [
	{
		path: '/test/imgMark',
		name: 'testImgMark',
		component: () => getRouteComponent('testImgMark', import('../views/test/ImgMark.vue')),
		meta: {
			noLogin: true,
			isConstRoute: true,
			deepth: 99,
		},
	},
	{
		path: '/test/latex',
		name: 'testLatex',
		component: () => getRouteComponent('testLatex', import('../views/test/Latex.vue')),
		meta: {
			noLogin: true,
			isConstRoute: true,
			deepth: 99,
		},
	},
	{
		path: '/demo',
		name: 'demo',
		component: () => getRouteComponent('demo', import('../views/demo.vue')),
		meta: {
			noLogin: true,
			isConstRoute: true,
			deepth: 99,
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () => getRouteComponent('login', import('@kzfe/learnable-lib/package/vue3/framework/views/Login.vue')), // 公共库中的登录页
		meta: {
			isConstRoute: true,
			noLogin: true,
			deepth: 0,
		},
	},
	{
		path: '/pdf-debug',
		name: 'templatePdfDebug',
		component: () => getRouteComponent('templateEdit', import('../views/templateManage/templateEdit/pdfPreviewDebug.vue')),
		meta: { title: '模板PDF预览', permission: 'exerciseBook:edit:debug', noLogin: false, deepth: 0 },
	},

	...frameworkRoutes,
	{
		path: '/questionBank',
		component: Main,
		redirect: '/questionBank/class',
		name: 'questionBank',
		meta: { title: '题库管理', permission: 'question-library', menuShow: true, icon: '&#xe78e;', deepth: 1 },
		children: [
			{
				path: '/questionBank/questionList',
				name: '/questionBank/questionList',
				component: () => getRouteComponent('/questionBank/questionList', import('../views/questionBank/question/questionList/Index.vue')),
				meta: { title: '题目管理', permission: 'question-library:question', menuShow: true, icon: '&#xe609;', homePage: true, deepth: 2 },
			},
			{
				path: '/questionBank/questionDetail',
				name: '/questionBank/questionDetail',
				component: () => getRouteComponent('/questionBank/questionDetail', import('../views/questionBank/question/questionDetail/Index.vue')),
				meta: { title: '题目编辑', permission: 'question-library:create_question', menuShow: false, icon: '&#xe609;', deepth: 3 },
			},
			{
				path: '/questionBank/mark',
				name: '/questionBank/mark',
				component: () => getRouteComponent('/questionBank/mark', import('../views/questionBank/questionMarkMange/Index.vue')),
				meta: { title: '标签管理', permission: 'question-library:tag-manage', menuShow: true, icon: '&#xe773;', deepth: 2 },
			},
			{
				path: '/questionBank/kpfQuery',
				name: '/questionBank/kpfQuery',
				component: () => getRouteComponent('/questionBank/kpfQuery', import('../views/questionBank/questionKPF/Index.vue')),
				meta: { title: '知识点格式查询', permission: 'question-library:knowledge_tag_query', menuShow: true, icon: '&#xe79f;', deepth: 2 },
			},
			{
				path: '/questionBank/simQuestionDetail',
				name: '/questionBank/simQuestionDetail',
				component: () => getRouteComponent('/questionBank/simQuestionDetail', import('../views/questionBank/questionSimMange/simDetail/Index.vue')),
				meta: { title: '相似题管理', permission: 'question-library:sim_question_manager', menuShow: false, icon: '&#xe912;', deepth: 3 },
			},
			{
				path: '/questionBank/simQuestionList',
				name: '/questionBank/simQuestionList',
				component: () => getRouteComponent('/questionBank/simQuestionList', import('../views/questionBank/questionSimMange/simList/Index.vue')),
				meta: { title: '相似组管理', permission: 'question-library:sim_group_manager', menuShow: true, icon: '&#xe912;', deepth: 2 },
			},
			{
				path: '/questionBank/checkRepeat',
				name: '/questionBank/checkRepeat',
				component: () => getRouteComponent('/questionBank/checkRepeat', import('../views/questionBank/questionCheckRepeat/Index.vue')),
				meta: { title: '题目查重', permission: 'question-library:question_check_repeat', menuShow: true, icon: '&#xe7b2;', deepth: 2 },
			},
			{
				path: '/questionBank/knowledgePointMarkTask',
				name: '/questionBank/knowledgePointMarkTask',
				component: () => getRouteComponent('/questionBank/knowledgePointMarkTask', import('../views/questionBank/knowledgePointMarkTask/Index.vue')),
				meta: { title: '知识点标记任务管理', permission: 'question-library:knowledge_tag_task', menuShow: true, icon: '&#xe76d;', deepth: 2 },
			},
			{
				path: '/questionBank/knowledgePointMarkTask/detail',
				name: '/questionBank/knowledgePointMarkTask/detail',
				component: () =>
					getRouteComponent('/questionBank/knowledgePointMarkTask/detail', import('../views/questionBank/knowledgePointMarkTask/DetailList.vue')),
				meta: { title: '知识点标记任务详情', permission: 'question-library:knowledge_tag_task', menuShow: false, icon: '&#xe76d;', deepth: 3 },
			},
			{
				path: '/questionBank/knowledgePointMarkTask/questionDetail',
				name: '/questionBank/knowledgePointMarkTask/questionDetail',
				component: () =>
					getRouteComponent('/questionBank/knowledgePointMarkTask/questionDetail', import('../views/questionBank/knowledgePointMarkTask/QuestionDetail.vue')),
				meta: { title: '知识点标记任务详情', permission: 'question-library:knowledge_tag_task:detail', menuShow: false, icon: '&#xe76d;', deepth: 3 },
			},
			{
				path: '/questionBank/knowledgePointMarkTask/mark',
				name: '/questionBank/knowledgePointMarkTask/mark',
				component: () => getRouteComponent('/questionBank/knowledgePointMarkTask/mark', import('../views/questionBank/knowledgePointMarkTask/mark/Index.vue')),
				meta: { title: '题目知识点标记', permission: 'question-library:knowledge_tag_task:mark_index', menuShow: true, icon: '&#xe76d;', deepth: 2 },
			},
			// 考点标记 question-library:examination_tag_task 权限路由
			{
				path: '/questionBank/examPointMarkTask',
				name: '/questionBank/examPointMarkTask',
				component: () => getRouteComponent('/questionBank/examPointMarkTask', import('../views/questionBank/questionExamMange/examList/Index.vue')),
				meta: { title: '考点标记', permission: 'question-library:examination_tag_task', menuShow: true, icon: '&#xe76d;', deepth: 2 },
			},
			{
				path: '/questionBank/examPointMarkTask/detail',
				name: '/questionBank/examPointMarkTask/detail',
				component: () => getRouteComponent('/questionBank/examPointMarkTask/detail', import('../views/questionBank/questionExamMange/examDetail/Index.vue')),
				meta: { title: '考点标记', permission: 'question-library:examination_tag_task', menuShow: false, icon: '&#xe912;', deepth: 3 },
			},
			{
				path: '/questionBank/examPointMarkTask/mark',
				name: '/questionBank/examPointMarkTask/mark',
				component: () => getRouteComponent('/questionBank/examPointMarkTask/mark', import('../views/questionBank/questionExamMange/examMark/Index.vue')),
				meta: { title: '考点标记', permission: 'question-library:examination_tag_task', menuShow: false, icon: '&#xe912;', deepth: 3 },
			},
			{
				path: '/questionBank/recommendStrategy',
				name: '/questionBank/recommendStrategy',
				component: () => getRouteComponent('/questionBank/recommendStrategy', import('../views/questionBank/recommendStrategy/Index.vue')),
				meta: { title: '推荐策略验证', permission: 'question-library:recommend_strategy', menuShow: true, icon: '&#xe78c;', deepth: 2 },
			},
			{
				path: '/questionBank/preciseQuestionDetail',
				name: '/questionBank/preciseQuestionDetail',
				component: () =>
					getRouteComponent('questionBank/preciseQuestionDetail', import('../views/questionBank/question/preciseGradingQuestionDetail/Index.vue')),
				meta: { title: '批改配置', permission: 'question-library:precise_question', menuShow: false, icon: '&#xe78c;', deepth: 2 },
			},
		],
	},
	{
		path: '/error-reason',
		component: Main,
		redirect: '/error-reason/class',
		name: 'errorReason',
		meta: { title: '错因管理', permission: 'error-reason', menuShow: true, icon: '&#xe65d;', deepth: 1 },
		children: [
			{
				path: '/error-reason/class',
				name: 'errorReasonClass',
				component: () => getRouteComponent('errorReasonClass', import('../views/errorReason/errorReasonClass.vue')),
				meta: { title: '错因类型管理', permission: 'error-reason:type_manage', icon: '&#xe65d;', menuShow: false, deepth: 2 },
			},
			{
				path: '/error-reason/list',
				name: 'errorReasonList',
				component: () => getRouteComponent('errorReasonList', import('../views/errorReason/errorReasonList.vue')),
				meta: { title: '错因管理', permission: 'error-reason:manage', menuShow: false, deepth: 3 },
			},
		],
	},
	{
		path: '/pattern',
		component: Main,
		redirect: '/pattern/manage',
		name: 'patter',
		meta: { title: '模式管理', permission: 'modeManage', menuShow: true, icon: '&#xe603;', deepth: 1 },
		children: [
			{
				path: '/pattern/manage',
				name: 'patterManage',
				component: () => getRouteComponent('patterManage', import('../views/pattern/patternManage.vue')),
				meta: { title: '模式管理', permission: 'modeManage:modeList', icon: '&#xe603;', menuShow: false, deepth: 1 },
			},
		],
	},
	{
		path: '/teachingbook',
		component: Main,
		redirect: '/teachingbook/manage',
		name: 'teachingBookManage',
		meta: { title: '教材管理', permission: 'chapter', menuShow: true, icon: '&#xe632;', deepth: 1 },
		children: [
			{
				path: '/teachingbook/manage',
				name: 'teachingBookManage',
				component: () => getRouteComponent('teachingBookManage', import('../views/teachingBookManage/textbookList.vue')),
				meta: { title: '教材管理', permission: 'chapter:textbook', icon: '&#xe632;', menuShow: false, deepth: 2 },
			},
			{
				path: '/teachingbook/chapter',
				name: 'teachingBookChapter',
				component: () => getRouteComponent('teachingBookChapter', import('../views/teachingBookManage/bookChanpterDetail.vue')),
				meta: { title: '教材章节管理', permission: 'chapter:subjectList', icon: '&#xe632;', menuShow: false, deepth: 3 },
			},
		],
	},
	{
		path: '/template',
		component: Main,
		redirect: '/template/manage',
		name: 'template',
		meta: { title: '模板管理', permission: 'exerciseBook', menuShow: true, icon: '&#xe604;', deepth: 1 },
		children: [
			{
				path: '/template/manage',
				name: 'templateManage',
				component: () => getRouteComponent('templateManage', import('../views/templateManage/templateManage.vue')),
				meta: { title: '模板管理', permission: 'exerciseBook:manage', icon: '&#xe64c;', menuShow: true, deepth: 2 },
			},
			{
				path: '/template/manageDetail',
				name: 'templateManageDetail',
				component: () => getRouteComponent('templateManageDetail', import('../views/templateManage/templateDetail/TemplateDetail.vue')),
				meta: { title: '版次管理', permission: 'exerciseBook:version', icon: '&#xe777;', menuShow: false, deepth: 3 },
			},
			{
				path: '/template/edit',
				name: 'templateEdit',
				component: () => getRouteComponent('templateEdit', import('../views/templateManage/templateEdit/templateEdit.vue')),
				meta: { title: '编辑模板', permission: 'exerciseBook:edit', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
			},
			{
				path: '/template/addQuestion',
				name: 'addQuestion',
				component: () => getRouteComponent('addQuestion', import('../views/templateManage/addQuestion/addQuestion.vue')),
				meta: { title: '选择试题', permission: 'exerciseBook:add', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
			},

			{
				path: '/template/person/manage',
				name: 'templatePersonManage',
				component: () => getRouteComponent('templatePersonManage', import('../views/templateManage/personTemplate/personTemplate.vue')),
				meta: { title: '个人模板管理', permission: 'exerciseBook:person-manage', icon: '&#xe7bd;', menuShow: true, deepth: 2 },
			},
			{
				path: '/template/person/edit',
				name: 'templatePersonEdit',
				component: () => getRouteComponent('templatePersonEdit', import('../views/templateManage/personTemplate/personTemplateEdit.vue')),
				meta: { title: '个人模板编辑', permission: 'exerciseBook:person-manage-edit', icon: '&#xe7bd;', menuShow: false, deepth: 3 },
			},
			{
				path: '/template/person/application',
				name: 'templatePersonApplication',
				component: () => getRouteComponent('templatePersonApplication', import('../views/templateManage/personApplication/personApplicationList.vue')),
				meta: { title: '题目入库申请', permission: 'exerciseBook:person-application', icon: '&#xe78b;', menuShow: true, deepth: 2 },
			},
			{
				path: '/template/importlist',
				name: 'templateImportList',
				component: () => getRouteComponent('templateImportList', import('../views/templateManage/importList.vue')),
				meta: { title: '导入任务', permission: 'exerciseBook:import', icon: '&#xe6ca;', menuShow: true, deepth: 2 },
			},
			{
				path: '/template/questionAudit',
				name: 'templateQuestionAudit',
				component: () => getRouteComponent('templateQuestionAudit', import('../views/templateManage/templateDetail/TemplateQuestionAudit.vue')),
				meta: { title: '内容审核', permission: 'exerciseBook:question:audit', icon: '&#xe6ca;', menuShow: false, deepth: 2 },
			},
		],
	},
	{
		path: '/quickEnter',
		component: Main,
		redirect: '/quickEnter/manage',
		name: 'quickEnterManage',
		meta: { title: '开放批改-快捷入库', permission: 'OpenGrader_QuickEnter', menuShow: true, icon: '&#xe632;', deepth: 1 },
		children: [
			{
				path: '/quickEnter/manage',
				name: 'quickEnterManage',
				component: () => getRouteComponent('quickEnterManage', import('../views/quickEnter/templateManage.vue')),
				meta: { title: '模板管理', permission: 'OpenGrader_QuickEnter', icon: '&#xe632;', menuShow: false, deepth: 2 },
			},
			{
				path: '/quickEnter/manageDetail',
				name: 'quickEnterManageDetail',
				component: () => getRouteComponent('templateManageDetail', import('../views/quickEnter/templateDetail/TemplateDetail.vue')),
				meta: { title: '版次管理', permission: 'OpenGrader_QuickEnter', icon: '&#xe777;', menuShow: false, deepth: 3 },
			},
			{
				path: '/quickEnter/labelling',
				name: 'quickEnterLabelling',
				component: () => getRouteComponent('quickEnterLabelling', import('../views/quickEnter/labelling/Index.vue')),
				meta: { title: '标注', permission: 'OpenGrader_QuickEnter', menuShow: false, deepth: 2 },
			},
			{
				path: '/quickEnter/label-check',
				name: 'quickEnterLabelCheck',
				component: () => getRouteComponent('quickEnterLabelCheck', import('../views/quickEnter/labelling/Index.vue')),
				meta: { title: '质检', permission: 'OpenGrader_QuickEnter', menuShow: false, deepth: 2 },
			},
			{
				path: '/quickEnter/check',
				name: 'quickEnterCheck',
				component: () => getRouteComponent('quickEnterCheck', import('../views/quickEnter/check/Index.vue')),
				meta: { title: '校验内容', permission: 'OpenGrader_QuickEnter', menuShow: false, deepth: 2 },
			},
			{
				path: '/quickEnter/edit',
				name: 'quickEnterEdit',
				component: () => getRouteComponent('quickEnterEdit', import('../views/quickEnter/templateEdit/templateEdit.vue')),
				meta: { title: '编辑模板', permission: 'OpenGrader_QuickEnter', icon: '&#xe6ca;', menuShow: false, deepth: 2 },
			},
		],
	},
	{
		path: '/templateEntry',
		component: Main,
		redirect: '/templateEntry/entryList',
		name: 'templateEntry',
		meta: { title: '入库管理', permission: 'templateEntry', menuShow: true, icon: '&#xe656;', deepth: 1 },
		children: [
			{
				path: '/templateEntry/entryList',
				name: 'entryList',
				component: () => getRouteComponent('templateEntryList', import('../views/templateEntry/entryList/Index.vue')),
				meta: { title: '模版管理', permission: 'templateEntry:list', icon: '&#xe6ca;', menuShow: true, deepth: 2 },
			},
			{
				path: '/templateEntry/chapterManage',
				name: 'chapterManage',
				component: () => getRouteComponent('chapterManage', import('../views/templateEntry/chapterTree/Index.vue')),
				meta: { title: '目录管理', permission: 'templateEntry:chapterManage', menuShow: false, deepth: 2 },
			},
			{
				path: '/templateEntry/sourceImageManage',
				name: 'sourceImageManage',
				component: () => getRouteComponent('chapterManage', import('../views/templateEntry/chapterTree/Index.vue')),
				meta: { title: '原图管理', permission: 'templateEntry:sourceImageManage', menuShow: false, deepth: 2 },
			},
			{
				path: '/templateEntry/labellingManage',
				name: 'labellingManage',
				component: () => getRouteComponent('chapterManage', import('../views/templateEntry/chapterTree/Index.vue')),
				meta: { title: '题目标注', permission: 'templateEntry:labellingManage', menuShow: false, deepth: 2 },
			},
			{
				path: '/templateEntry/qualityInspectionManage',
				name: 'qualityInspectionManage',
				component: () => getRouteComponent('chapterManage', import('../views/templateEntry/chapterTree/Index.vue')),
				meta: { title: '质量检查', permission: 'templateEntry:qualityInspectionManage', menuShow: false, deepth: 2 },
			},
			{
				path: '/templateEntry/manualLabelling',
				name: 'manualLabelling',
				component: () => getRouteComponent('manualLabelling', import('../views/templateEntry/labelling/Index.vue')),
				meta: { title: '题目标注', permission: 'templateEntry:manualLabelling', menuShow: false, deepth: 2 },
			},
			{
				path: '/templateEntry/answerLabelling',
				name: 'answerLabelling',
				component: () => getRouteComponent('answerLabelling', import('../views/templateEntry/labelling/Index.vue')),
				meta: { title: '答案标注', permission: 'templateEntry:answerLabelling', menuShow: false, deepth: 2 },
			},
			{
				path: '/templateEntry/answerCorrection',
				name: 'answerCorrection',
				component: () => getRouteComponent('answerCorrection', import('../views/templateEntry/labelling/Index.vue')),
				meta: { title: '答案修正', permission: 'templateEntry:answerCorrection', menuShow: false, deepth: 2 },
			},
			{
				path: '/templateEntry/qualityInspection',
				name: 'qualityInspection',
				component: () => getRouteComponent('qualityInspection', import('../views/templateEntry/labelling/Index.vue')),
				meta: { title: '质量检查', permission: 'templateEntry:qualityInspection', menuShow: false, deepth: 2 },
			},
		],
	},
	{
		path: '/positionLabel',
		component: Main,
		redirect: '/positionLabel/list',
		name: 'positionLabel',
		meta: { title: '位置标注管理', permission: 'positionLabel', menuShow: true, icon: '&#xe879;', deepth: 1 },
		children: [
			{
				path: '/positionLabel/list',
				name: 'positionLabelList',
				component: () => getRouteComponent('positionLabelList', import('../views/positionLabel/manageList/Index.vue')),
				meta: { title: '位置标注管理', permission: 'positionLabel:manageList', icon: '&#xe6ca;', menuShow: true, deepth: 2 },
			},
			{
				path: '/positionLabel/label',
				name: 'positionLabelLabel',
				component: () => getRouteComponent('positionLabelLabel', import('../views/positionLabel/labelling/Index.vue')),
				meta: { title: '位置标注', permission: 'positionLabel:labelling', menuShow: false, deepth: 2 },
			},
			{
				path: '/positionLabel/algorithmData',
				name: 'algorithmData',
				component: () => getRouteComponent('algorithmData', import('../views/positionLabel/algorithmData.vue')),
				meta: { title: '检查位置标注', permission: 'positionLabel:algorithmData', menuShow: false, deepth: 2 },
			},
		],
	},
	{
		path: '/examinationPaper',
		component: Main,
		redirect: '/examinationPaper/list',
		name: 'examinationPaper',
		meta: { title: '试卷管理', permission: 'examinationPaper', menuShow: true, icon: '&#xe61e;', deepth: 1 },
		children: [
			{
				path: '/template/examinationPaper',
				name: 'templateExaminationPaper',
				component: () => getRouteComponent('templateExaminationPaper', import('../views/templateManage/examinationPaper/examinationPaperList/Index.vue')),
				meta: { title: 'C端试卷管理', permission: 'exerciseBook:examinationPaper', icon: '&#xe64a;', menuShow: true, deepth: 2 },
			},
			{
				path: '/template/examinationPaper/detail',
				name: 'templateExaminationPaperDetail',
				component: () =>
					getRouteComponent('templateExaminationPaperDetail', import('../views/templateManage/examinationPaper/examinationPaperDetail/Index.vue')),
				meta: { title: 'C端试卷管理详情', permission: 'exerciseBook:examinationPaper', icon: '&#xe6ca;', menuShow: false, deepth: 2 },
			},
			{
				path: '/examinationPaper/list',
				name: 'examinationPaperList',
				component: () => getRouteComponent('examinationPaperList', import('../views/examinationPaper/examinationPaperList/Index.vue')),
				meta: { title: 'B端试卷管理', permission: 'examinationPaper:examinationPaperList', icon: '&#xe64a;', menuShow: true, deepth: 2 },
			},
			{
				path: '/examinationPaper/detail',
				name: 'examinationPaperDetail',
				component: () => getRouteComponent('examinationPaperDetail', import('../views/examinationPaper/examinationPaperDetail/Index.vue')),
				meta: { title: 'B端试卷管理详情', permission: 'examinationPaper:examinationPaperDetail', icon: '&#xe6ca;', menuShow: false, deepth: 2 },
			},
			{
				path: '/examinationImPaper/list',
				name: 'examinationImPaperList',
				component: () => getRouteComponent('examinationImPaperList', import('../views/examinationImPaper/examinationImPaperList/Index.vue')),
				meta: { title: '押题试卷管理', permission: 'examinationImPaper:examinationImPaperList', icon: '&#xe64a;', menuShow: true, deepth: 2 },
			},
		],
	},
	// {
	// 	path: '/examinationImPaper',
	// 	component: Main,
	// 	redirect: '/examinationImPaper/list',
	// 	name: 'examinationImPaper',
	// 	meta: { title: '押题试卷管理', permission: 'examinationImPaper', menuShow: true, icon: '&#xe61e;', deepth: 1 },
	// 	children: [
	// 		{
	// 			path: '/examinationImPaper/list',
	// 			name: 'examinationImPaperList',
	// 			component: () => getRouteComponent('examinationImPaperList', import('../views/examinationImPaper/examinationImPaperList/Index.vue')),
	// 			meta: { title: '押题试卷管理', permission: 'examinationImPaper:examinationImPaperList', icon: '&#xe64a;', menuShow: true, deepth: 2 },
	// 		},
	// 	],
	// },
	{
		path: '/knowledgePoint',
		component: Main,
		redirect: '/knowledgePoint/list',
		name: 'knowledgePoint',
		meta: { title: '知识体系管理', permission: 'knowledgePoint', menuShow: true, icon: '&#xe606;', deepth: 1 },
		children: [
			{
				path: '/knowledgePoint/tree',
				name: 'knowledgePointTree',
				component: () => getRouteComponent('knowledgePointTree', import('../views/knowledgePointManage/knowledgePoint/Index.vue')),
				meta: { title: '学科知识点管理', permission: 'knowledgePoint:knowledgePointTree', icon: '&#xe6ca;', menuShow: true, deepth: 2 },
			},
		],
	},
	// 考点路由，examinationPoint 路由权限
	{
		path: '/examinationPoint',
		component: Main,
		redirect: '/examinationPoint/list',
		name: 'examinationPoint',
		meta: { title: '考点体系管理', permission: 'examinationPoint', menuShow: true, icon: '&#xe7ab;', deepth: 1 },
		children: [
			{
				path: '/examinationPoint/tree',
				name: 'examinationPointTree',
				component: () => getRouteComponent('examinationPointTree', import('../views/examinationPointManage/examinationPoint/Index.vue')),
				meta: { title: '学科考点管理', permission: 'examinationPoint:examinationPointTree', icon: '&#xe6ca;', menuShow: true, deepth: 2 },
			},
		],
	},
	{
		path: '/markingManage',
		component: Main,
		redirect: '/markingManage/taskManage',
		name: 'markingManage',
		meta: { title: '标注管理', permission: 'markingManage', menuShow: true, icon: '&#xec58;', deepth: 1 },
		children: [
			{
				path: '/markingManage/orgManage',
				name: 'orgManage',
				component: () => getRouteComponent('knowledgePointTree', import('../views/markingManage/orgManage/orgList.vue')),
				meta: { title: '外包机构管理', permission: 'markingManage:orgManage', icon: '&#xe6ae;', menuShow: true, deepth: 2 },
			},
			{
				path: '/markingManage/orgUserManage',
				name: 'orgUserManage',
				component: () => getRouteComponent('knowledgePointTree', import('../views/markingManage/orgManage/userList.vue')),
				meta: { title: '机构人员管理', permission: 'markingManage:orgUserManage', icon: '&#xeb81;', menuShow: false, deepth: 3 },
			},
			// 任务管理
			{
				path: '/markingManage/taskManage',
				name: 'markingManageTaskManage',
				component: () => getRouteComponent('markingManageTaskManage', import('../views/markingManage/taskManage/TaskList.vue')),
				meta: { title: '任务管理', permission: 'markingManage:taskManage', icon: '&#xe6ca;', menuShow: true, deepth: 2 },
			},
			{
				path: '/markingManage/chapterManage',
				name: 'markingManageChapterManage',
				component: () => getRouteComponent('markingManageChapterManage', import('../views/markingManage/chapterManage/ChapterManage.vue')),
				meta: { title: '章节管理', permission: 'markingManage:chapterManage', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
			},
			{
				path: '/markingManage/sourceImageManage',
				name: 'markingManageSourceImageManage',
				component: () => getRouteComponent('markingManageSourceImageManage', import('../views/markingManage/sourceImageManage/SourceImageManage.vue')),
				meta: { title: '原图管理', permission: 'markingManage:sourceImageManage', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
			},
			// 题目内容标注(入库)
			{
				path: '/markingManage/entry',
				redirect: '/markingManage/entry/list',
				name: 'markingManageEntry',
				meta: { title: '题目内容标注', permission: 'markingManage:entryList', icon: '&#xe6ca;', menuShow: true, deepth: 2 },
				children: [
					{
						path: '/markingManage/entry/list',
						name: 'markingManageEntryList',
						component: () => getRouteComponent('markingManageEntryList', import('../views/markingManage/entryLabellingManage/list/EntryList.vue')),
						meta: { title: '', permission: 'markingManage:entryList', icon: '&#xe6ca;', menuShow: false, deepth: 2 },
					},
					{
						path: '/markingManage/entry/labelling',
						name: 'markingManageEntryLabelling',
						redirect: '/markingManage/entry/detail/labelling',
						children: [
							{
								path: '/markingManage/entry/detail/labelling',
								name: 'markingManageEntryDetailLabelling',
								component: () =>
									getRouteComponent('markingManageEntryDetailLabelling', import('../views/markingManage/entryLabellingManage/detail/EntryDetailLabelling.vue')),
								meta: { title: '', permission: 'markingManage:entryList:labelling', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
							},
							{
								path: '/markingManage/entry/op/labelling',
								name: 'markingManageEntryOPLabelling',
								component: () =>
									getRouteComponent('markingManageEntryOPLabelling', import('../views/markingManage/entryLabellingManage/labelling/EntryLabelling.vue')),
								meta: { title: '题目标注', permission: 'markingManage:entryOP:labelling', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
							{
								path: '/markingManage/entry/op/answerLabelling',
								name: 'markingManageEntryOPAnswerLabelling',
								component: () =>
									getRouteComponent('markingManageEntryOPAnswerLabelling', import('../views/markingManage/entryLabellingManage/labelling/EntryLabelling.vue')),
								meta: { title: '答案标注', permission: 'markingManage:entryOP:answerLabelling', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
							{
								path: '/markingManage/entry/op/answerCorrection',
								name: 'markingManageEntryOPAnswerCorrection',
								component: () =>
									getRouteComponent('markingManageEntryOPAnswerCorrection', import('../views/markingManage/entryLabellingManage/labelling/EntryLabelling.vue')),
								meta: { title: '答案修正', permission: 'markingManage:entryOP:answerCorrection', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
							{
								path: '/markingManage/entry/op/qualityInspectionProblemResolve',
								name: 'markingManageEntryOPQualityInspectionProblemResolve',
								component: () =>
									getRouteComponent(
										'markingManageEntryOPQualityInspectionProblemResolve',
										import('../views/markingManage/entryLabellingManage/labelling/EntryLabelling.vue')
									),
								meta: {
									title: '质检问题处理',
									permission: 'markingManage:entryOP:qualityInspectionProblemResolve',
									icon: '&#xe6ca;',
									menuShow: false,
									deepth: 4,
								},
							},
						],
					},
					{
						path: '/markingManage/entry/qualityInspection',
						name: 'markingManageEntryQualityInspection',
						redirect: '/markingManage/entry/detail/qualityInspection',
						children: [
							{
								path: '/markingManage/entry/detail/qualityInspection',
								name: 'markingManageEntryDetailQualityInspection',
								component: () =>
									getRouteComponent(
										'markingManageEntryDetailQualityInspection',
										import('../views/markingManage/entryLabellingManage/detail/EntryDetailLabelling.vue')
									),
								meta: { title: '', permission: 'markingManage:entryList:qualityInspection', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
							},
							{
								path: '/markingManage/entry/op/qualityInspection',
								name: 'markingManageEntryOPQualityInspection',
								component: () =>
									getRouteComponent(
										'markingManageEntryOPQualityInspection',
										import('../views/markingManage/entryLabellingManage/labelling/EntryLabelling.vue')
									),
								meta: { title: '质量检查', permission: 'markingManage:entryOP:qualityInspection', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
						],
					},
					{
						path: '/markingManage/entry/acceptance',
						name: 'markingManageEntryAcceptance',
						redirect: '/markingManage/entry/detail/acceptance',
						children: [
							{
								path: '/markingManage/entry/detail/acceptance',
								name: 'markingManageEntryDetailAcceptance',
								component: () =>
									getRouteComponent(
										'markingManageEntryDetailAcceptance',
										import('../views/markingManage/entryLabellingManage/detail/EntryDetailLabelling.vue')
									),
								meta: { title: '', permission: 'markingManage:entryList:acceptance', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
							},
							{
								path: '/markingManage/entry/op/acceptance',
								name: 'markingManageEntryOPAcceptance',
								component: () =>
									getRouteComponent('markingManageEntryOPAcceptance', import('../views/markingManage/entryLabellingManage/labelling/EntryLabelling.vue')),
								meta: { title: '验收', permission: 'markingManage:entryOP:acceptance', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
						],
					},
				],
			},

			// 坐标位置标注
			{
				path: '/markingManage/positionList',
				name: 'markingManagePositionList',
				component: () => getRouteComponent('markingManagePositionList', import('../views/markingManage/positionLabellingManage/list/PositionList.vue')),
				meta: { title: '坐标位置标注', permission: 'markingManage:positionList', icon: '&#xe6ca;', menuShow: true, deepth: 2 },
			},
			{
				path: '/markingManage/positionList/labelling',
				name: 'markingManagePositionLabelling',
				component: () => getRouteComponent('markingManagePositionLabelling', import('../views/markingManage/positionLabellingManage/labelling/Index.vue')),
				meta: { title: '坐标位置标注', permission: 'markingManage:positionList', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
			},
			{
				path: '/markingManage/positionList/transfer',
				name: 'markingManagePositionTransfer',
				component: () => getRouteComponent('markingManagePositionTransfer', import('../views/markingManage/positionLabellingManage/transfer/index.vue')),
				meta: { title: '坐标位置标注', permission: 'markingManage:positionList', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
			},
			{
				path: '/markingManage/duplicateCheck',
				name: 'markingManageDuplicateCheck',
				redirect: '/markingManage/duplicateCheckList',
				meta: { title: '查重', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: true, deepth: 2 },
				children: [
					{
						path: '/markingManage/duplicateCheckList',
						name: 'markingManageDuplicateCheckList',
						component: () => getRouteComponent('markingManageDuplicateCheckList', import('../views/markingManage/duplicateCheck/list/index.vue')),
						meta: { title: '', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
					},
					{
						path: '/markingManage/duplicateCheckLabelling',
						name: 'markingManageDuplicateCheckLabelling',
						redirect: '/markingManage/duplicateCheckLabelling/index',
						meta: { title: '标注', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
						children: [
							{
								path: '/markingManage/duplicateCheckLabelling/index',
								name: 'markingManageDuplicateCheckLabellingIndex',
								component: () =>
									getRouteComponent('markingManageDuplicateCheckLabellingIndex', import('../views/markingManage/duplicateCheck/labelling/index.vue')),
								meta: { title: '', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
							{
								path: '/markingManage/duplicateCheckLabelling/detail',
								name: 'markingManageDuplicateCheckLabellingDetail',
								component: () =>
									getRouteComponent('markingManageDuplicateCheckLabellingDetail', import('../views/markingManage/duplicateCheck/labelling/detail.vue')),
								meta: { title: '详情', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
							{
								path: '/markingManage/duplicateCheckLabelling/feedback',
								name: 'markingManageDuplicateCheckLabellingFeedback',
								component: () =>
									getRouteComponent('markingManageDuplicateCheckLabellingFeedback', import('../views/markingManage/duplicateCheck/labelling/feedback.vue')),
								meta: { title: '质检问题处理', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
						],
					},
					{
						path: '/markingManage/duplicateCheckTesting',
						name: 'markingManageDuplicateCheckTesting',
						redirect: '/markingManage/duplicateCheckTesting/index',
						meta: { title: '质检', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
						children: [
							{
								path: '/markingManage/duplicateCheckTesting/index',
								name: 'markingManageDuplicateCheckTestingIndex',
								component: () =>
									getRouteComponent('markingManageDuplicateCheckTestingIndex', import('../views/markingManage/duplicateCheck/testing/index.vue')),
								meta: { title: '', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
							{
								path: '/markingManage/duplicateCheckTesting/detail',
								name: 'markingManageDuplicateCheckTestingDetail',
								component: () =>
									getRouteComponent('markingManageDuplicateCheckTestingDetail', import('../views/markingManage/duplicateCheck/testing/detail.vue')),
								meta: { title: '详情', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
						],
					},
					{
						path: '/markingManage/duplicateCheckAcceptance',
						name: 'markingManageDuplicateCheckAcceptance',
						redirect: '/markingManage/duplicateCheckAcceptance/index',
						meta: { title: '验收', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
						children: [
							{
								path: '/markingManage/duplicateCheckAcceptance/index',
								name: 'markingManageDuplicateCheckAcceptanceIndex',
								component: () =>
									getRouteComponent('markingManageDuplicateCheckAcceptanceIndex', import('../views/markingManage/duplicateCheck/acceptance/index.vue')),
								meta: { title: '', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
							{
								path: '/markingManage/duplicateCheckAcceptance/detail',
								name: 'markingManageDuplicateCheckAcceptanceDetail',
								component: () =>
									getRouteComponent('markingManageDuplicateCheckAcceptanceDetail', import('../views/markingManage/duplicateCheck/acceptance/detail.vue')),
								meta: { title: '详情', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
						],
					},
				],
			},
			{
				path: '/markingManage/duplicateCheck/transfer',
				name: 'markingManageDuplicateCheckTransfer',
				component: () => getRouteComponent('markingManageDuplicateCheckTransfer', import('../views/markingManage/duplicateCheck/transfer/index.vue')),
				meta: { title: '查重', permission: 'markingManage:duplicateCheckList', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
			},
			// 题目目录配置
			{
				path: '/markingManage/entryInfoConfig',
				redirect: '/markingManage/entryInfoConfig/list',
				name: 'markingManageEntryInfoConfig',
				meta: { title: '题目信息配置', permission: 'markingManage:entryInfoConfigList', icon: '&#xe6ca;', menuShow: true, deepth: 2 },
				children: [
					{
						path: '/markingManage/entryInfoConfig/list',
						name: 'markingManageEntryInfoConfigList',
						component: () => getRouteComponent('markingManageEntryInfoConfigList', import('../views/markingManage/entryInfoConfig/List.vue')),
						meta: { title: '', permission: 'markingManage:entryInfoConfigList', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
					},
					{
						path: '/markingManage/entryInfoConfig/labelling',
						name: 'markingManageEntryInfoConfigLabelling',
						redirect: '/markingManage/entryInfoConfig/detail/labelling',
						meta: { title: '标注', permission: 'markingManage:entryInfoConfig:labelling', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
						children: [
							{
								path: '/markingManage/entryInfoConfig/detail/labelling',
								name: 'markingManageEntryInfoConfigDetailLabelling',
								component: () =>
									getRouteComponent('markingManageEntryInfoConfigDetailLabelling', import('../views/markingManage/entryInfoConfig/detail/Labelling.vue')),
								meta: { title: '标注', permission: 'markingManage:entryInfoConfig:labelling', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
							{
								path: '/markingManage/entryInfoConfig/op/labelling',
								name: 'markingManageEntryInfoConfigOPLabelling',
								component: () =>
									getRouteComponent('markingManageEntryInfoConfigOPLabelling', import('../views/markingManage/entryInfoConfig/operate/Labelling.vue')),
								meta: { title: '题型配置及几何画图', permission: 'markingManage:entryOP:labelling', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
							{
								path: '/markingManage/entryInfoConfig/op/qualityInspectionProblem',
								name: 'markingManageEntryInfoConfigOPQualityInspectionProblem',
								component: () =>
									getRouteComponent(
										'markingManageEntryInfoConfigOPQualityInspectionProblem',
										import('../views/markingManage/entryInfoConfig/operate/QualityInspectionProblem.vue')
									),
								meta: {
									title: '质量检查问题',
									permission: 'markingManage:entryOP:qualityInspectionProblemResolve',
									icon: '&#xe6ca;',
									menuShow: false,
									deepth: 4,
								},
							},
						],
					},
					{
						path: '/markingManage/entryInfoConfig/qualityInspection',
						name: 'markingManageEntryInfoConfigQualityInspection',
						redirect: '/markingManage/entryInfoConfig/detail/qualityInspection',
						meta: { title: '质检', permission: 'markingManage:entryInfoConfig:qualityInspection', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
						children: [
							{
								path: '/markingManage/entryInfoConfig/detail/qualityInspection',
								name: 'markingManageEntryInfoConfigDetailQualityInspection',
								component: () =>
									getRouteComponent(
										'markingManageEntryInfoConfigDetailQualityInspection',
										import('../views/markingManage/entryInfoConfig/detail/QualityInspection.vue')
									),
								meta: { title: '', permission: 'markingManage:entryInfoConfig:qualityInspection', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
							{
								path: '/markingManage/entryInfoConfig/op/qualityInspection',
								name: 'markingManageEntryInfoConfigOPQualityInspection',
								component: () =>
									getRouteComponent(
										'markingManageEntryInfoConfigOPQualityInspection',
										import('../views/markingManage/entryInfoConfig/operate/QualityInspection.vue')
									),
								meta: { title: '质量检查', permission: 'markingManage:entryOP:qualityInspection', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
						],
					},
					{
						path: '/markingManage/entryInfoConfig/acceptance',
						name: 'markingManageEntryInfoConfigAcceptance',
						redirect: '/markingManage/entryInfoConfig/detail/acceptance',
						meta: { title: '验收', permission: 'markingManage:entryInfoConfig:acceptance', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
						children: [
							{
								path: '/markingManage/entryInfoConfig/detail/acceptance',
								name: 'markingManageEntryInfoConfigDetailAcceptance',
								component: () =>
									getRouteComponent('markingManageEntryInfoConfigDetailAcceptance', import('../views/markingManage/entryInfoConfig/detail/Acceptance.vue')),
								meta: { title: '', permission: 'markingManage:entryInfoConfig:acceptance', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
							{
								path: '/markingManage/entryInfoConfig/op/acceptance',
								name: 'markingManageEntryInfoConfigOPAcceptance',
								component: () =>
									getRouteComponent('markingManageEntryInfoConfigOPAcceptance', import('../views/markingManage/entryInfoConfig/operate/Acceptance.vue')),
								meta: { title: '验收', permission: 'markingManage:entryOP:acceptance', icon: '&#xe6ca;', menuShow: false, deepth: 4 },
							},
						],
					},
				],
			},
			{
				path: '/markingManage/globalWorkloadStatistic',
				redirect: '/markingManage/globalWorkloadStatistic/list',
				name: 'markingManageGlobalWorkloadStatistic',
				meta: { title: '全局工作量统计', permission: 'markingManage:globalWorkloadStatisticList', icon: '&#xe6ca;', menuShow: true, deepth: 2 },
				children: [
					{
						path: '/markingManage/globalWorkloadStatistic/list',
						name: 'markingManageGlobalWorkloadStatisticList',
						component: () => getRouteComponent('markingManageGlobalWorkloadStatistic', import('../views/markingManage/globalWorkloadStatistic/List.vue')),
						meta: { title: '', permission: 'markingManage:globalWorkloadStatisticList', icon: '&#xe6ca;', menuShow: false, deepth: 3 },
					},
				],
			},
		],
	},
]

export const constRoutes = recursionGetConstRoute(routes)
const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: constRoutes,
})
trackRouter(router)

export function settingRoutes() {
	settingRoutesG(router, routes)
}

initFramework(import.meta.env, G, store, router, DOMAIN_MAP, settingRoutes)

export default router

NProgress.configure({ showSpinner: false })

router.beforeEach((to, from, next) => {
	NProgress.start()
	routerBeforeEach(to, from, next)
})

router.beforeEach((to, from, next) => {
	setKeepAliveAndTransition(to, from)
	next()
})

router.afterEach(to => {
	if (to.path.startsWith('/system')) {
		const mainContent = document.querySelector('.main-content')
		if (mainContent) {
			mainContent.style.overflow = 'auto'
		}
	}
	NProgress.done()
	setPageTitle(to.meta.title)
	if (isLogin()) {
		const module = store.state[VuexModuleKey.enumModule] as {
			[x: string]: unknown
		}
		if (module) {
			const serverEnum = module[VuexStateKey.serverAllEnum] as {
				[key: string]: EnumItem
			}
			const keys = Object.keys(serverEnum)
			if (!keys.length) {
				store.dispatch(`${VuexModuleKey.enumModule}/${VuexActionKey.queryAllEnum}`)
			}
		}
	}
})
