import { VuexStateKeyG, VuexMutationKeyG } from '@kzfe/learnable-lib/package/vue3/framework/store/vuexEnum'

export class VuexStateKey extends VuexStateKeyG {
	/**枚举相关key */
	static serverAllEnum = 'serverAllEnum'
	static pageEnumList = 'pageEnumList'
	static userList = 'userList'
	static qualityTestErrorEnum = 'qualityTestErrorEnum'
	static operationType = 'operationType'
}
export class VuexMutationKey extends VuexMutationKeyG {
	static updateServerAllEnum = 'updateServerAllEnum'
	static updateUserList = 'updateUserList'
	static updateQualityTestErrorEnum = 'updateQualityTestErrorEnum'
	static updateOperationType = 'updateOperationType'
	/**枚举相关key */
}
export class VuexActionKey {
	static queryAllEnum = 'queryAllEnum'
	static queryUserList = 'queryUserList'
	static queryQualityTestErrorEnum = 'queryQualityTestErrorEnum'
}
export class VuexModuleKey {
	static enumModule = 'enumModule'
	static labellingModule = 'labellingModule'
}
