import { useStore } from 'vuex'
import { AllTypeEnumList, EnumItem } from '@/api/enum'
import http from '@kzfe/learnable-lib/package/vue3/framework/utils/http'
import { EntryLabellingBoundingBoxType } from '@/views/markingManage/entryLabellingManage/labelling/entities/boundingBoxType'
import { UserInfo } from 'types/UserInfo'
import { uid } from 'uid'

/**
 * 标注完成
 * 所有子任务标注完成使用这一个接口
 * @param params NewLabellingResolveQ
 * @returns boolean
 */
export interface NewLabellingResolveQ {
	chapterId: string
	subTaskType?: AllTypeEnumList['bookImportSubTaskTypeEnum'][number]['index']
	uid?: string
}
export function apiNewResolveManualLabelling(params: NewLabellingResolveQ) {
	return http<boolean>(
		'GET',
		'/exerciseBookImportBizTask/markSuccess',
		{ ...params },
		{
			headers: {
				uid: params.uid,
			},
		}
	)
}

/**
 * 按子任务查询质检反馈错误类型
 * @param params subTaskType
 * @returns EnumItem[]
 */

export function apiQueryQualityTestErrorEnumByType(params: { subTaskType: AllTypeEnumList['bookImportSubTaskTypeEnum'][number]['index'] }) {
	return http<EnumItem[]>('GET', '/enums/queryQualityTestErrorEnumByType', { ...params })
}

/**
 * 保存质检反馈记录
 */
export interface NewQualityInspectionFeedbackQ {
	subTaskType: AllTypeEnumList['bookImportSubTaskTypeEnum'][number]['index']
	chapterId: string
	imgId?: string
	qualityTestFeedbackUnitInner: {
		chapterId: string
		pageNo: number
		questionNo: number | string
		questionId: number | string
	}
	errorTypeList?: number[]
	errorDesc?: string
}

export function apiNewSaveQualityInspectionFeedback(params: NewQualityInspectionFeedbackQ) {
	return http<boolean>('POST', '/exerciseBookImportBizTask/saveQualityTestFeedback', { ...params })
}

/**
 * 质检退回
 */
export function apiNewQQualityInspectionRollback(params: NewLabellingResolveQ) {
	return http<boolean>(
		'GET',
		'/exerciseBookImportBizTask/qualityTestRollBack',
		{ ...params },
		{
			headers: {
				uid: params.uid,
			},
		}
	)
}

/**
 * 质检完成
 */
export function apiNewQQualityInspectionSuccess(params: NewLabellingResolveQ) {
	return http<boolean>(
		'GET',
		'/exerciseBookImportBizTask/qualityTestSuccess',
		{ ...params },
		{
			headers: {
				uid: params.uid,
			},
		}
	)
}

/**
 * 在章节状态为验收回退时: 质检再次完成
 */
export function apiNewQQualityInspectionAgainSuccess(params: NewLabellingResolveQ) {
	return http<boolean>(
		'GET',
		'/exerciseBookImportBizTask/qualityTestAgainSuccess',
		{ ...params },
		{
			headers: {
				uid: params.uid,
			},
		}
	)
}

/**
 * 重新标注完成
 *
 */
export function apiNewMarkAgainSuccess(params: NewLabellingResolveQ) {
	return http<boolean>(
		'GET',
		'/exerciseBookImportBizTask/markAgainSuccess',
		{ ...params },
		{
			headers: {
				uid: params.uid,
			},
		}
	)
}

/**
 * 查询质检反馈记录
 */

export interface NewQualityInspectionFeedbackDTO {
	qualityTestFeedbackUnitInner: {
		pageNo: number
		questionNo: number
		questionId: number | string
	}
	errorType: string
	errorDesc: string
	operationUser: string
	operationUserId: number
	createTime: string
}
export function apiNewQueryQualityTestErrorFeedback(params: NewLabellingResolveQ) {
	return http<NewQualityInspectionFeedbackDTO[]>('GET', '/exerciseBookImportBizTask/queryQualityTestFeedback', params, { domainName: 'questionBmp' })
}

/**
 * 查询验收记录
 */
export interface NewAcceptanceFeedbackDTO {
	conclusionDesc: string
	remark: string
	extractQuestion: number
	accuracy: number
	acceptanceUserId: number
	acceptanceUserName: string
	createTime: string
}

export function apiNewQueryAcceptanceFeedback(params: { exerciseBookId: string; subTaskType: AllTypeEnumList['bookImportSubTaskTypeEnum'][number]['index'] }) {
	return http<NewAcceptanceFeedbackDTO[]>('GET', '/exerciseBookImportBizTask/queryAcceptanceRecord', params, { domainName: 'questionBmp' })
}

/**
 * 查询练习册验收指标
 */
export interface NewAcceptanceBaseQ {
	exerciseBookId?: string
	subTaskType?: AllTypeEnumList['bookImportSubTaskTypeEnum'][number]['index']
	chapterId?: string
}

export interface ChapterAcceptanceIndicators {
	chapterId: number | string
	questionNum: number
	extractQuestionNum: number
	accuracy: number
}
export interface BookAcceptanceIndicators {
	extractQuestionNum: number
	accuracy: number
	chapterInfo: ChapterAcceptanceIndicators[]
}

export function apiNewQueryBookAcceptanceIndicators(params: NewAcceptanceBaseQ) {
	return http<BookAcceptanceIndicators>('GET', '/exerciseBookImportBizTask/queryBookAcceptanceIndicators', params)
}

/**
 * 设置抽取比例
 * @param params NewSetExtractRatioQ
 */
export interface NewSetExtractRatioQ extends NewAcceptanceBaseQ {
	extractRatio: number
}

export function apiNewSetExtractRatio(params: NewSetExtractRatioQ) {
	return http<boolean>('POST', '/exerciseBookImportBizTask/setExtractRatio', params)
}

/**
 * 查询抽取比例
 * @param params NewSetExtractRatioQ
 */
export function apiNewQueryExtractRatio(params: NewAcceptanceBaseQ) {
	return http<number>('GET', '/exerciseBookImportBizTask/queryExtractRatio', params)
}

/**
 * 设置验收结论
 * @param params NewSetAcceptanceConclusionQ
 */
export interface NewSetAcceptanceConclusionQ extends NewAcceptanceBaseQ {
	conclusion: number
	remark: string
	extractQuestion: number
	accuracy: number
}
export function apiNewSetAcceptanceConclusion(params: NewSetAcceptanceConclusionQ) {
	return http<boolean>('POST', '/exerciseBookImportBizTask/setAcceptanceConclusion', params)
}

/**
 * 按一级章节查询抽取的题目图片
 */
export interface NewQueryExtractQuestionImageDTO {
	fileName: string
	sourceFileName: string
	url: string
	thumbnailUrl: string
	orderIdx: number
	boundingBoxList: EntryLabellingBoundingBoxType[]
	imageId: string
	ocrImageId: string
	pageNo: number
}

/**
 * 验收时 查询抽检的题目图片列表
 * @param params
 * @returns
 */
export function apiQueryAcceptanceImgByChapter(params: NewAcceptanceBaseQ) {
	return http<NewQueryExtractQuestionImageDTO[]>('GET', '/exerciseBookImportBizTask/queryAcceptanceImgByChapter', params)
}

/**
 * 验收时 查询抽检的答案图片列表
 * @param params
 * @returns
 */
export function apiQueryAcceptanceAnswerImgByChapter(params: NewAcceptanceBaseQ) {
	return http<NewQueryExtractQuestionImageDTO[]>('GET', '/exerciseBookImportBizTask/queryAcceptanceAnswerImgByChapter', params)
}

/**
 * 验收题目单题 修改标识 保存
 */
export interface NewAcceptanceQuestionSaveDTO {
	subTaskType: AllTypeEnumList['bookImportSubTaskTypeEnum'][number]['index']
	exerciseBookId: string // 练习册ID
	chapterId: string // 章节id
	questionId?: number // 题目id
	imageId?: number
	oldData?: string // 旧数据
	newData?: string // 新数据
}

export function apiAcceptanceUpdateRecordSave(params: NewAcceptanceQuestionSaveDTO) {
	return http<boolean>('POST', '/exerciseBookImportBizTask/saveAcceptanceUpdateRecord', params)
}

export function apiAcceptanceUpdateRecordQuery(params: NewAcceptanceQuestionSaveDTO) {
	return http<NewAcceptanceQuestionSaveDTO[]>('POST', '/exerciseBookImportBizTask/queryAcceptanceUpdateRecord', params)
}

/**
 * 验收完成
 */
export function apiNewAcceptanceSuccess(params: NewLabellingResolveQ) {
	return http<boolean>('GET', '/exerciseBookImportBizTask/acceptanceSuccess', params, {
		headers: {
			uid: params.uid,
		},
	})
}
